import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError,map,take } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { TypeDashboard } from 'src/app/domain/dashboard/dashboard';
import { ReferentielList,TypeReferentielList } from 'src/app/domain/referentiel-list/referentiel-list';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReferentielListService {
	/** Structure des référentiels de liste **/
	private listeReferentielLists: Array<ReferentielList> = [{
		type: TypeReferentielList.COMMERCE,
		url: '/Commerce/loadDashboard',
		listeItems: [{
			field: 'nbOffres',
			libelle: 'referentielList.commerce.offres',
			redirectionUrl: '/Offre/listeOffres',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_OFFRE,'consultation')
		},{
			field: 'nbClients',
			libelle: 'referentielList.commerce.clientsCRM',
			redirectionUrl: '/ClientCRM/listeClientsCRM',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_CLIENT_CRM,'consultation')
		},{
			field: 'nbContrats',
			libelle: 'referentielList.commerce.contratsCRM',
			redirectionUrl: '/ContratCRM/listeContratsCRM',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_CONTRAT_CRM,'consultation')
		},{
			field: 'nbArticles',
			libelle: 'referentielList.commerce.articlesCRM',
			redirectionUrl: '/ArticleCRM/listeArticlesCRM',
			icone: 'shopping_basket',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_ARTICLE_CRM,'consultation')
		}]
	},{
		type: TypeReferentielList.COMPTABILITE,
		url: '/Compte/loadDashboard',
		listeItems: [{
			field: 'nbPlansComptables',
			libelle: 'referentielList.comptabilite.plansComptables',
			redirectionUrl: '/Comptabilite/listePlanComptables',
			icone: 'n-icon-plan-comptable',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => !rightService.isRoot()
		},{
			field: 'nbComptes',
			libelle: 'referentielList.comptabilite.comptesCollectifs',
			redirectionUrl: '/Comptabilite/listeComptes',
			icone: 'n-icon-compte',
			iconeType: 'icon-venus'
		},{
			field: 'nbComptesAuxiliaires',
			libelle: 'referentielList.comptabilite.comptesAuxiliaires',
			redirectionUrl: '/Comptabilite/listeComptesAuxiliaires',
			icone: 'n-icon-compte-auxiliaire',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => !rightService.isRoot()
		},{
			field: 'nbStructuresExport',
			libelle: 'referentielList.comptabilite.structuresExport',
			redirectionUrl: '/StructureExport/listeStructuresExport',
			icone: 'open_in_browser'
		}]
	},{
		type: TypeReferentielList.GEOGRAPHIE,
		url: '/Geographie/loadDashboard',
		listeItems: [{
			field: 'nbPays',
			libelle: 'referentielList.geographie.pays',
			redirectionUrl: '/Geographie/listePays',
			icone: 'public'
		}]
	},{
		type: TypeReferentielList.FISCALITE,
		url: '/Fiscalite/loadDashboard',
		listeItems: [{
			field: 'nbTaxes',
			libelle: 'referentielList.fiscalite.taxes',
			redirectionUrl: 'Fiscalite/listeTaxes',
			icone: { libelle: 'referentielList.fiscalite.TVA' },
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_TAXE,'consultation')
		},{
			field: 'nbReglesTaxes',
			libelle: 'referentielList.fiscalite.reglesTaxe',
			redirectionUrl: 'Fiscalite/listeReglesTaxes',
			icone: 'n-icon-recuperation-tva',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_PLAN_COMPTABLE,'consultation')
		},{
			field: 'nbAvantageNatureAbattements',
			libelle: 'referentielList.fiscalite.abattementsAvantageNature',
			redirectionUrl: 'Fiscalite/listeAvantageNatureAbattements',
			icone: 'electric_car',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_AVANTAGE_NATURE_ABATTEMENT,'consultation')
		}]
	},{
		type: TypeReferentielList.FOURNISSEUR,
		url: '/Fournisseur/loadDashboard',
		listeItems: [{
			field: 'nbFournisseurs',
			libelle: 'referentielList.fournisseur.fournisseurs',
			redirectionUrl: '/Fournisseur/listeFournisseurs',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_FOURNISSEUR,'consultation')
		},{
			field: 'nbNatures',
			libelle: 'referentielList.fournisseur.natures',
			redirectionUrl: '/Fournisseur/listeNatures',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_FOURNISSEUR_NATURE,'consultation')
		},{
			field: 'nbInterfaces',
			libelle: 'referentielList.fournisseur.interfaces',
			redirectionUrl: '/Fournisseur/listeInterfaces',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,'consultation') && rightService.isRoot()
		},{
			field: 'nbCodes',
			libelle: 'referentielList.fournisseur.codes',
			redirectionUrl: '/Fournisseur/listeCodes',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_FOURNISSEUR_INTERFACE,'consultation') && rightService.isRoot()
		},{
			field: 'nbConnecteurTypes',
			libelle: 'referentielList.fournisseur.connecteurTypes',
			redirectionUrl: '/Connecteur/listeConnecteurTypes',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.CONNECTEUR,'consultation') && rightService.isRoot()
		}]
	},{
		type: TypeReferentielList.MATERIEL,
		url: '/Equipement/loadDashboard',
		listeItems: [{
			field: 'nbEquipements',
			libelle: 'referentielList.materiel.equipements',
			redirectionUrl: `/DashboardRedirector/${TypeDashboard.EQUIPEMENT}`,
			icone: 'credit_card',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.EQUIPEMENT,'consultation')

		},{
			field: 'nbAmenagements',
			libelle: 'referentielList.materiel.amenagements',
			redirectionUrl: '/Amenagement/listeAmenagements',
			icone: 'n-icon-amenagement',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_AMENAGEMENT,'consultation')
		}]
	},{
		type: TypeReferentielList.DEMANDE_IDENTIFICATION,
		url: '/Societe/loadDashboard',
		listeItems: [{
			field: 'nbSocietes',
			libelle: 'referentielList.demandeIdentification.societes',
			redirectionUrl: '/Societe/listeSocietes',
			icone: 'public'
		}]
	},{
		type: TypeReferentielList.COLLABORATEUR,
		url: '/UserReferentiel/loadDashboard',
		listeItems: [{
			field: 'nbCategories',
			libelle: 'referentielList.collaborateur.categories',
			redirectionUrl: '/Collaborateur/listeCategories',
			icone: 'person',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR_CATEGORIE,'consultation')
		}]
	},{
		type: TypeReferentielList.VEHICULE_INFRACTION,
		url: '/DemandeIdentification/loadDashboard',
		listeItems: [{
			field: 'nbDemandes',
			libelle: 'referentielList.vehiculeInfraction.identifications',
			redirectionUrl: `/DashboardRedirector/${TypeDashboard.DEMANDE_IDENTIFICATION}`,
			icone: 'n-icon-demande-identification',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION,'consultation')
		},{
			field: 'nbContraventions',
			libelle: 'referentielList.vehiculeInfraction.contraventions',
			redirectionUrl: `/DashboardRedirector/${TypeDashboard.CONTRAVENTION}`,
			icone: 'n-icon-contravention',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_CONTRAVENTION,'consultation')
		}]
	},{
		type: TypeReferentielList.VEHICULE_COMMANDE,
		url: '/VehiculeCommande/loadDashboard',
		listeItems: [{
			field: 'nbDemandesCotation',
			libelle: 'referentielList.vehiculeCommande.demandesCotation',
			redirectionUrl: `/DashboardRedirector/${TypeDashboard.DEMANDE_COTATION}`,
			icone: 'compare',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.DEMANDE_COTATION,'consultation')
		},{
			field: 'nbDemandesVehicule',
			libelle: 'referentielList.vehiculeCommande.demandesVehicule',
			redirectionUrl: `/DashboardRedirector/${TypeDashboard.DEMANDE_VEHICULE}`,
			icone: 'add_shopping_cart',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'consultation')
		}]
	},{
		type: TypeReferentielList.VEHICULE,
		url: '/VehiculeReferentiel/loadDashboard',
		listeItems: [{
			field: 'nbGenres',
			libelle: 'referentielList.vehicule.genres',
			redirectionUrl: '/Vehicule/listeGenres',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_GENRE,'consultation') && rightService.isRoot()
		},{
			field: 'nbMarques',
			libelle: 'referentielList.vehicule.marques',
			redirectionUrl: '/Vehicule/listeMarques',
			icone: 'n-icon-car-store',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_MARQUE,'consultation') && rightService.isRoot()
		},{
			field: 'nbModeles',
			libelle: 'referentielList.vehicule.modeles',
			redirectionUrl: '/Vehicule/listeModeles',
			icone: 'n-icon-car-shine',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_MODELE,'consultation')
		},{
			field: 'nbCarburants',
			libelle: 'referentielList.vehicule.carburants',
			redirectionUrl: '/Vehicule/listeCarburants',
			icone: 'n-icon-car-carburant',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_CARBURANT,'consultation') && rightService.isRoot()
		},{
			field: 'nbTypesCarrosserie',
			libelle: 'referentielList.vehicule.carrosseries',
			redirectionUrl: '/Vehicule/listeTypesCarrosserie',
			icone: 'n-icon-car-traffic-jam',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_TYPE_CARROSSERIE,'consultation') && rightService.isRoot()
		},{
			field: 'nbTypesInfraction',
			libelle: 'referentielList.vehicule.infractions',
			redirectionUrl: '/Vehicule/listeTypesInfraction',
			icone: 'gavel',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_TYPE_INFRACTION,'consultation') && rightService.isRoot()
		},{
			field: 'nbPlansEntretien',
			libelle: 'referentielList.vehicule.plansEntretien',
			redirectionUrl: '/Vehicule/listePlansEntretien',
			icone: 'n-icon-plan-entretien',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ENTRETIEN,'consultation') && !rightService.isRoot()
		},{
			field: 'nbControlesReglementaires',
			libelle: 'referentielList.vehicule.controlesReglementaires',
			redirectionUrl: '/Vehicule/listeControlesReglementaires',
			icone: 'n-icon-car-traffic-jam',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.CONTROLE_REGLEMENTAIRE,'consultation') && rightService.isRoot()
		},{
			field: 'nbTypesAmenagement',
			libelle: 'referentielList.vehicule.typesAmenagement',
			redirectionUrl: '/Vehicule/listeTypesAmenagement',
			icone: 'n-icon-amenagement',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_TYPE_AMENAGEMENT,'consultation') && !rightService.isRoot()
		},{
			field: 'nbOptionsCategories',
			libelle: 'referentielList.vehicule.categoriesOptions',
			redirectionUrl: '/Vehicule/listeOptionsCategories',
			icone: 'n-icon-car-vehicles',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_REFERENTIEL_OPTION_CATEGORIE,'consultation') && rightService.isRoot()
		},{
			field: 'nbTypesEntretien',
			libelle: 'referentielList.vehicule.typesEntretien',
			redirectionUrl: '/Vehicule/listeTypesEntretien',
			icone: 'n-icon-plan-entretien',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ENTRETIEN,'consultation') && !rightService.isRoot()
		},{
			field: 'nbPools',
			libelle: 'referentielList.vehicule.pools',
			redirectionUrl: '/Vehicule/listePools',
			icone: 'event',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.VEHICULE_POOL,'consultation') && !rightService.isRoot()
		},{
			field: 'nbEtatLieuxTemplates',
			libelle: 'referentielList.vehicule.etatLieuxTemplate',
			redirectionUrl: '/Vehicule/listeEtatLieuxTemplates',
			icone: 'n-icon-etat-des-lieux',
			iconeType: 'icon-venus',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ETAT_LIEUX,'consultation') && rightService.isRoot(true)
		}]
	},{
		type: TypeReferentielList.DEVISE,
		url: '/Devise/loadDashboard',
		listeItems: [{
			field: 'nbDevises',
			libelle: 'referentielList.devise.devises',
			redirectionUrl: '/Devise/listeDevises',
			icone: 'attach_money',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_DEVISE,'consultation')
		},{
			field: 'nbDevisesCours',
			libelle: 'referentielList.devise.coursDevises',
			redirectionUrl: '/Devise/listeCours',
			icone: 'attach_money',
			hasRight: (rightService: RightService) => rightService.hasRight(TypeDroit.ADMIN_DEVISE,'consultation') && rightService.isRoot()
		}]
	}];

	/**
	 * Constructeur
	 */
	constructor(private httpClient: HttpClient,private rightService: RightService) { }

	/**
	 * Récupération du référentiel de listes pour un type donné
	 */
	public retrieveReferentielList(typeReferentielList: TypeReferentielList): Observable<ReferentielList> {
		let referentielList: ReferentielList;

		//Recherche et copie du réfentiel de liste associé au type
		referentielList = Object.assign({},this.listeReferentielLists.find(r => r.type === typeReferentielList));

		//Vérification de la présence d'un référentiel de liste
		if (referentielList) {
			//Vérification de la présence d'un droit d'accès et filtrage des éléments de la liste
			referentielList.listeItems = referentielList.listeItems.filter(i => !i?.hasRight || i?.hasRight(this.rightService));

			//Récupération des données
			return this.httpClient.post<Result>(`${environment.baseUrl}/controller${referentielList.url}`,null).pipe(
				take(1),
				map(result => {
					//Vérification de la présence d'une erreur
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Itération sur les éléments
						referentielList.listeItems.forEach(item => {
							//Définition du nombre d'éléments
							item.counter = result.data[item.field] || 0;
						});

						//Retour du référentiel
						return referentielList;
					}
				}),
				catchError(() => {
					//Génération d'une erreur
					throw new Error(`L'appel à '${referentielList.url}' au type de référentiel de liste '${typeReferentielList}' a échoué`);
				})
			);
		} else
			//Génération d'une erreur
			throw new Error(`Le type de référentiel de liste '${typeReferentielList}' n'est pas défini dans la structure du service`);
	}
}